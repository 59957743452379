import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This section will be expanded in the future. In the mean time, the `}<a parentName="p" {...{
        "href": "https://uniswap.org/whitepaper.pdf"
      }}>{`Uniswap V2 whitepaper`}</a>{` has most relevant math for Uniswap V2.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      